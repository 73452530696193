import * as React from "react"
// import { globalStyles } from "../../stitches.config"

import Box from "./box"
import Text from "./text"

const Bio = () => {
  return (
    <Box
      as="section"
      css={{
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        paddingTop: "20vh",
        boxSizing: "border-box",
        paddingBottom: "$128",

        p: {
          fontSize: "$20",
          lineHeight: "1.4",
          marginY: "$8",
        },

        "@bp1": {
          height: "calc(100dvh - 88px)",
          marginTop: "0",
          paddingBottom: "$half",
        },
      }}
    >
      <Text
        css={{
          fontSize: "$20",
          lineHeight: "1.5",
        }}
      >
        Hey
      </Text>
      <Text
        css={{
          fontSize: "$20",
          lineHeight: "1.5",
        }}
      >
        My name is Kiryl, I'm a product designer in StarOfService. This website
        is intended as a place to accumullate some of my thoughts or activities.
        Currently, I'm on my first step—writing articles based on some of my
        recent initiatives. You can find those below.
      </Text>
    </Box>
  )
}

export default Bio
