import * as React from "react"
import { Link, graphql } from "gatsby"

import { useMixpanel } from "gatsby-plugin-mixpanel"

import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Box from "../components/box"
import Text from "../components/text"
import Bio from "../components/bio"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes

  const mixpanel = useMixpanel()

  if (posts.length === 0) {
    return (
      <Layout title={siteTitle}>
        <Seo title="kiryl kavalenka, product designer" description="yo" />
        <Footer />
        <p>nothing's here</p>
      </Layout>
    )
  }

  return (
    <Layout title={siteTitle}>
      <Seo title="kiryl kavalenka, product designer" description="yo" />
      <Bio />
      <Box as="section">
        <Text as="h1" css={{ marginBottom: "$32", fontSize: "$14" }}>
          Recent writings:
        </Text>

        <ol
          style={{ listStyle: `none`, paddingLeft: "0", marginBottom: "24px" }}
        >
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug}>
                <Box
                  as="article"
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                  css={{
                    ":hover": {
                      h2: {
                        textDecoration: "underline",
                      },
                    },
                  }}
                >
                  <Link
                    to={post.fields.slug}
                    itemProp="url"
                    style={{
                      textDecoration: "none",
                      width: "100%",
                    }}
                    onClick={() =>
                      mixpanel.track("Open link", {
                        source: "Home",
                        to: post.fields.slug,
                      })
                    }
                  >
                    <Text
                      as="h2"
                      itemProp="headline"
                      css={{
                        marginBottom: "$8",
                        fontSize: "$base",
                      }}
                    >
                      {title}
                    </Text>
                    <Text css={{ fontSize: "$14", marginBottom: "$24" }}>
                      {post.frontmatter.date}
                    </Text>
                  </Link>
                </Box>
              </li>
            )
          })}
        </ol>
        <Link to="/writings">
          <Text as="h3" css={{ marginY: "$32", fontSize: "$14" }}>
            all writings
          </Text>
        </Link>
      </Box>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`

// mdx(frontmatter: { date: { eq: "2022-11-27" } }) {
//   id
//   body
//   frontmatter {
//     title
//     date(formatString: "MMMM DD, YYYY")
//     description
//     cover {
//       childrenImageSharp {
//         gatsbyImageData(
//           blurredOptions: { width: 120 }
//           width: 720
//           placeholder: BLURRED
//         )
//       }
//     }
//   }
//   fields {
//     slug
//   }
//   headings {
//     depth
//     value
//   }
// }
